import { IconArrow } from "components/Icon/IconArrow";
import { IconArrowDown } from "components/Icon/IconArrowDown";
import { trackEvent } from "lib/amplitude";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { Image } from "components/Image/Image";
import { IconQuiz } from "components/Icon/IconQuiz";
import { IconAssignment } from "components/Icon/IconAssignment";
import { IconArticle } from "components/Icon/IconArticle";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { IconAngle } from "components/Icon/IconAngle";
import { useAuthStore } from "modules/Auth/authStore";
import { IconLesson } from "components/Icon/IconLesson";
import dynamic from "next/dynamic";

const Image = dynamic(
  () => import("components/Image/Image").then((mod) => mod.Image),
  {
    ssr: false,
  }
);

const minusIcon = "-";
const plusIcon = "+";

type Props = {
  title?: string;
  description?: string;
  index?: number;
  length?: number;
  isSeparated?: boolean;
  eventName?: any;
  eventProperties?: any;
  className?: string;
  data?: any[];
  countLesson?: any;
};

export const AccordionWithImage: React.FC<Props> = ({
  title,
  description,
  eventName = "",
  eventProperties = {},
  index = 0,
  length = 0,
  isSeparated = false,
  className = "",
  data,
  countLesson = 0,
}) => {
  const loginState = useAuthStore((state) => state.loginState);
  const router = useRouter();
  const contentScrollRef = useRef<HTMLDivElement>(null);

  const [currentScrollLeft, setCurrentScrollLeft] = useState(0);
  const [currentScrollWidth, setCurrentScrollWidth] = useState(0);
  const [currentContainerWidth, setCurrentContainerWidth] = useState(0);
  const [currentContainerHeight, setCurrentContainerHeight] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (contentScrollRef.current) {
      setCurrentScrollLeft(contentScrollRef.current.scrollLeft);
      setCurrentScrollWidth(contentScrollRef.current.scrollWidth);

      setCurrentContainerWidth(contentScrollRef.current.clientWidth);
      setCurrentContainerHeight(contentScrollRef.current.clientHeight);

      // @ts-ignore
      contentScrollRef.current.addEventListener("scroll", () => {
        setCurrentScrollLeft(contentScrollRef.current.scrollLeft);
      });
    }
  }, [contentScrollRef.current]);

  const toggleExpanded = useCallback(() => {
    setExpanded((current) => {
      const status = !current ? "open" : "close";
      const eventprops = {
        ...eventProperties,
        dropdown_name: title,
        state: status,
      };

      trackEvent({
        event: eventName,
        property: eventprops,
      });
      return !current;
    });
  }, [eventName, eventProperties, title]);

  let images = data[0]?.image ?? null;

  return (
    <div
      className={`relative py-12 px-16 cursor-pointer bg-white ${className} ${
        isSeparated
          ? "rounded-16 border border-neutral20_to_dark30"
          : index == 0 && length == 1
          ? "rounded-16"
          : index == 0 && length > 1
          ? "rounded-t-16"
          : index == length - 1
          ? "rounded-b-16"
          : ""
      }`}
    >
      <div
        className="text-left relative items-center select-none flex justify-between flex-row"
        onClick={toggleExpanded}
      >
        {expanded ? (
          <h5 className="font-b1 font-medium text-left flex items-center pl-4">
            {/* {(data as any)?.length ?? 0} lessons in the course */}
            {t("totalLesson", {
              count: countLesson,
              additionalS: (data as any)?.length > 1 ? "s" : "",
            })}{" "}
            {t("thisCourse")}
          </h5>
        ) : (
          <div className="flex gap-8 align-center">
            {images ? (
              <div className="rounded-8 bg-white flex">
                {expanded ? (
                  <div className="w-[172px] h-[96px] z-0">
                    <Image
                      src={images}
                      alt="images"
                      width={172}
                      height={96}
                      className="rounded-8"
                    />
                  </div>
                ) : (
                  <div className="relative">
                    <div className="w-[92px] h-[52px] z-0">
                      <Image
                        src={images}
                        alt="images"
                        width={92}
                        height={52}
                        className="rounded-8"
                      />
                    </div>

                    <div className="w-[92px] h-[52px] absolute top-0 z-4">
                      <Image
                        src={images}
                        alt="images"
                        width={92}
                        height={52}
                        className="rounded-8"
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            <h5
              className={`font-b1 font-medium text-left flex items-center ${
                countLesson > 1 ? "ml-16" : ""
              }`}
            >
              {t("totalLesson", {
                count: countLesson,
                additionalS: (data as any)?.length > 1 ? "s" : "",
              })}
            </h5>
          </div>
        )}

        <div className="flex-none pl-2">
          {expanded ? (
            <IconArrow isCaret={true} direction="up" />
          ) : (
            <IconArrowDown size={20} />
          )}
        </div>
      </div>

      <div className="relative">
        <div
          className={`overflow-x-scroll relative flex gap-8 duration-500 transition-[max-height] ease-out ${
            expanded ? "max-h-[220px]" : "max-h-0"
          }`}
          ref={contentScrollRef}
        >
          {data.map((item, index) => (
            <div className="flex flex-col gap-4">
              <h5
                className={`text-b2 px-4 font-medium text-left flex items-center mt-8 ${
                  item.lessons.length === 1
                    ? "w-[170px] line-clamp-1"
                    : "line-clamp-1"
                }`}
              >
                {item.name ?? `Chapter ${index + 1}`}
              </h5>
              <div className="flex gap-8 font-light">
                {item.lessons.map((lesson) => (
                  <div
                    className="grid gap-2 hover:bg-purple-20 p-4 rounded-8"
                    onClick={() => {
                      router.push(
                        loginState == "LOGIN"
                          ? {
                              pathname: "/course/[courseSlug]",
                              query: {
                                courseSlug: lesson.course_slug,
                                lesson: lesson.slug,
                              },
                            }
                          : {
                              pathname: "/login",
                              query: {
                                url: encodeURIComponent(
                                  `/course/${lesson.course_slug}&lesson=${lesson.slug}`
                                ),
                              },
                            }
                      );
                    }}
                  >
                    {expanded ? (
                      <div
                        className={`w-[172px] h-[96px] z-0 mb-4 flex justify-center items-center rounded-8 ${
                          ["Video", "AWS", "IFrame", "SCORM"].includes(
                            lesson.type
                          )
                            ? ""
                            : "bg-white"
                        }`}
                      >
                        {["Video", "AWS", "IFrame", "SCORM"].includes(
                          lesson.type
                        ) ? (
                          <Image
                            src={lesson.image}
                            alt="images"
                            width={172}
                            height={96}
                            className="rounded-8"
                          />
                        ) : (
                          <div
                            className={`w-[172px] h-[96px] flex justify-center items-center rounded-8`}
                          >
                            <div className="w-full bg-white bg-[url('/images/bg-lesson.png')] h-full rounded-8 bg-no-repeat bg-center bg-cover flex justify-center items-center">
                              <div className="rounded-full w-[44px] p-8 aspect-square bg-white flex justify-center items-center">
                                <IconLesson type={lesson.type} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="w-[92px] h-[52px] z-0">
                        <Image
                          src={lesson.image}
                          alt="images"
                          width={92}
                          height={52}
                          className="rounded-8"
                        />
                      </div>
                    )}

                    <span className="w-[172px] font-medium text-b2 text-neutral-80 truncate text-left">
                      {lesson.name}
                    </span>
                    {["Video", "AWS", "IFrame", "SCORM"].includes(
                      lesson.type
                    ) &&
                    lesson.duration &&
                    lesson.duration !== 0 ? (
                      <span className="text-c1 font-light text-neutral-50">
                        {lesson.duration} {t("minutes")}
                      </span>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* scroll left */}
        {currentScrollLeft > 0 && expanded ? (
          <div
            className="absolute left-[-36px] top-[72px] bg-white w-32 h-32 flex justify-center items-center rounded-full cursor-pointer shadow-navigation"
            // style={{
            //   // top: currentContainerHeight / 2 - 20,
            //   top: 45 % currentContainerHeight,
            // }}
            onClick={(e) => {
              if (contentScrollRef.current) {
                contentScrollRef.current.scroll({
                  left: contentScrollRef.current.scrollLeft - 336,
                  behavior: "smooth",
                });
              }
            }}
          >
            <IconAngle />
          </div>
        ) : null}

        {/* scroll right */}
        {currentScrollWidth > currentContainerWidth &&
        expanded &&
        currentScrollLeft < currentScrollWidth - currentContainerWidth ? (
          <div
            className="absolute right-[-36px] top-[72px] bg-white w-32 h-32 flex justify-center items-center rounded-full cursor-pointer shadow-navigation"
            // style={{
            //   // top: currentContainerHeight / 2 - 20,
            //   top: 45 % currentContainerHeight,
            // }}
            onClick={(e) => {
              if (contentScrollRef.current) {
                contentScrollRef.current.scroll({
                  left: contentScrollRef.current.scrollLeft + 336,
                  behavior: "smooth",
                });
              }
            }}
          >
            <IconAngle direction="right" />
          </div>
        ) : null}
      </div>
    </div>
  );
};
