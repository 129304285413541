type Props = {
  size?: number;
  color?: string;
  type?:
    | "Video"
    | "Quiz"
    | "SCORM"
    | "IFrame"
    | "Webinar"
    | "PDF"
    | "Assignment"
    | "Article"
    | "AWS";
};

export const IconLesson: React.FC<Props> = ({
  size = 24,
  color = "#3E3851",
  type = "Video",
}) => {
  if (type === "Quiz") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0609 23.9981H3.99554C3.24715 23.9981 2.64844 23.3981 2.64844 22.6482V3.44975C2.64844 2.69981 3.24715 2.09985 3.99554 2.09985H20.0609C20.8093 2.09985 21.408 2.69981 21.408 3.44975V22.6482C21.408 23.3481 20.8093 23.9981 20.0609 23.9981Z"
          fill="#FACAB3"
        />
        <path
          d="M20.058 2.04987H8.18359V2.64982C8.18359 2.94979 8.43304 3.19977 8.7324 3.19977H21.4051C21.3053 2.54983 20.7066 2.04987 20.058 2.04987Z"
          fill="#F7A983"
        />
        <path
          d="M15.2701 1.54987H14.2224C13.923 1.54987 13.6236 1.34989 13.5239 1.04991C13.3243 0.449962 12.7256 0 12.077 0C11.4284 0 10.8297 0.449962 10.6301 1.04991C10.5303 1.34989 10.231 1.54987 9.93163 1.54987H8.88387C8.53462 1.54987 8.28516 1.84985 8.28516 2.14983V2.94976C8.28516 3.09975 8.38494 3.19974 8.53462 3.19974H15.7191C15.8688 3.19974 15.9686 3.09975 15.9686 2.94976V2.14983C15.8688 1.79985 15.5695 1.54987 15.2701 1.54987ZM12.0271 0.999918C12.3264 0.999918 12.5759 1.2499 12.5759 1.54987H11.4783C11.4783 1.2499 11.7277 0.999918 12.0271 0.999918Z"
          fill="#7447CC"
        />
        <path
          d="M10.3247 6.59943H7.08165C6.98187 6.59943 6.88208 6.64942 6.83219 6.69942L6.28335 7.24937C6.23346 7.29937 6.18359 7.39936 6.18359 7.49935V11.599C6.18359 11.699 6.23346 11.799 6.28335 11.849L6.83219 12.399C6.88208 12.449 6.98187 12.4989 7.08165 12.4989H10.3247C10.4244 12.4989 10.5242 12.449 10.5741 12.399L11.1229 11.849C11.1728 11.799 11.2227 11.699 11.2227 11.599V7.49935C11.2227 7.39936 11.1728 7.29937 11.1229 7.24937L10.5741 6.69942C10.5242 6.64942 10.4244 6.59943 10.3247 6.59943ZM8.0795 8.0993H9.37669C9.57626 8.0993 9.77583 8.24929 9.77583 8.49927V10.4491L8.92768 9.3992C8.82789 9.29921 8.67821 9.29921 8.57843 9.3492C8.47864 9.44919 8.47862 9.59918 8.52851 9.69918L9.22702 10.5491C9.3767 10.7491 9.22701 10.9991 9.02744 10.9991H8.0795C7.87993 10.9991 7.68037 10.8491 7.68037 10.5991V8.44928C7.68037 8.29929 7.87993 8.0993 8.0795 8.0993Z"
          fill="#7447CC"
        />
        <path
          d="M17.8089 7.44934H13.2188V7.9493H17.8089V7.44934Z"
          fill="white"
        />
        <path
          d="M17.8089 8.69925H13.2188V9.19921H17.8089V8.69925Z"
          fill="white"
        />
        <path
          d="M17.8089 9.9491H13.2188V10.4491H17.8089V9.9491Z"
          fill="white"
        />
        <path
          d="M15.6635 11.1991H13.2188V11.6991H15.6635V11.1991Z"
          fill="white"
        />
        <path
          d="M4.88945 23.8982C7.58983 23.8982 9.77891 21.7045 9.77891 18.9986C9.77891 16.2926 7.58983 14.099 4.88945 14.099C2.18908 14.099 0 16.2926 0 18.9986C0 21.7045 2.18908 23.8982 4.88945 23.8982Z"
          fill="#551FC1"
        />
        <path
          d="M19.1082 23.8982C21.8086 23.8982 23.9977 21.7045 23.9977 18.9986C23.9977 16.2926 21.8086 14.099 19.1082 14.099C16.4078 14.099 14.2188 16.2926 14.2188 18.9986C14.2188 21.7045 16.4078 23.8982 19.1082 23.8982Z"
          fill="#7447CC"
        />
        <path
          d="M18.2115 20.6483L17.8623 20.2984C17.7126 20.1484 17.7126 19.9484 17.8623 19.7984L20.307 17.3486C20.4567 17.1986 20.6562 17.1986 20.8059 17.3486L21.1552 17.6986C21.3049 17.8486 21.3049 18.0486 21.1552 18.1985L18.7104 20.6483C18.5608 20.7483 18.3612 20.7483 18.2115 20.6483Z"
          fill="white"
        />
        <path
          d="M17.1162 19.0485L17.4654 18.6985C17.6151 18.5485 17.8147 18.5485 17.9643 18.6985L19.062 19.7984C19.2117 19.9484 19.2117 20.1484 19.062 20.2984L18.7127 20.6483C18.5631 20.7983 18.3635 20.7983 18.2138 20.6483L17.1162 19.5484C16.9665 19.3984 16.9665 19.1485 17.1162 19.0485Z"
          fill="white"
        />
        <path
          d="M3.64511 20.6484L3.29585 20.2984C3.14617 20.1484 3.14617 19.9484 3.29585 19.7984L5.74059 17.3486C5.89027 17.1986 6.08984 17.1986 6.23952 17.3486L6.58877 17.6986C6.73845 17.8486 6.73845 18.0486 6.58877 18.1986L4.14403 20.6484C3.99435 20.7484 3.79478 20.7484 3.64511 20.6484Z"
          fill="white"
        />
        <path
          d="M3.2389 17.6986L3.58815 17.3486C3.73783 17.1986 3.9374 17.1986 4.08708 17.3486L6.53179 19.7984C6.68147 19.9484 6.68147 20.1484 6.53179 20.2984L6.18257 20.6483C6.03289 20.7983 5.83332 20.7983 5.68364 20.6483L3.2389 18.1985C3.13912 18.0486 3.13912 17.8486 3.2389 17.6986Z"
          fill="white"
        />
      </svg>
    );
  } else if (type === "Article") {
    return (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9961 22L0.345093 18.4549C0.138093 18.3916 -0.00390625 18.2014 -0.00390625 17.9854V0.491298C-0.00390625 0.142264 0.351598 -0.0952405 0.675598 0.0372035L11.9961 4.65581V22Z"
          fill="#7447CC"
        />
        <path
          d="M11.9961 22L23.6471 18.4549C23.8541 18.3916 23.9961 18.2014 23.9961 17.9854V0.491298C23.9961 0.142264 23.6406 -0.0952405 23.3166 0.0372035L11.9961 4.65581V22Z"
          fill="#F7A983"
        />
        <path
          d="M21.2699 7.33803L14.7344 9.66676V8.85765L21.2699 6.52893V7.33803Z"
          fill="#FACAB3"
        />
        <path
          d="M21.2699 10.2374L14.7344 12.4003V11.5912L21.2699 9.42828V10.2374Z"
          fill="#FACAB3"
        />
        <path
          d="M21.2699 12.9963L14.7344 15.1334V14.3243L21.2699 12.1872V12.9963Z"
          fill="#FACAB3"
        />
        <path
          d="M9.99609 21.3916L11.9961 22V4.65634L9.99609 3.84027V21.3916Z"
          fill="#551FC1"
        />
      </svg>
    );
  } else if (type === "Assignment") {
    return (
      <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.161 0C18.7074 0 19.9961 1.2 19.9961 2.75V21.25C19.9961 22.75 18.759 24 17.161 24H2.83114C1.28475 24 -0.00390625 22.8 -0.00390625 21.25V5.89999C-0.00390625 5.54999 0.150739 5.25 0.356924 5.05L5.20229 0.350006C5.46002 0.100006 5.76931 0 6.07859 0H17.161V0Z"
          fill="#FACAB3"
        />
        <path
          d="M5.25383 0.350037C5.35692 0.250037 5.51156 0.150037 5.61466 0.100037V2.85004C5.61466 4.85004 3.96517 6.45003 1.90331 6.45003H-0.00390625V5.95003C-0.00390625 5.60003 0.150739 5.30004 0.356924 5.10004L5.25383 0.350037Z"
          fill="#F7A983"
        />
        <path
          d="M0.148438 5.45003C0.199984 5.30003 0.303076 5.20004 0.406169 5.10004L5.25151 0.400024C5.3546 0.300024 5.50924 0.200024 5.61234 0.150024V2.75003C5.61234 4.25003 4.37522 5.50003 2.77729 5.50003H0.148438V5.45003Z"
          fill="#7447CC"
        />
        <path
          d="M5.92451 9.25003H4.79052C4.58433 9.25003 4.42969 9.10002 4.42969 8.90002V8.25003C4.42969 8.05003 4.58433 7.90002 4.79052 7.90002H5.92451C6.1307 7.90002 6.28534 8.05003 6.28534 8.25003V8.85004C6.28534 9.10004 6.1307 9.25003 5.92451 9.25003Z"
          fill="white"
        />
        <path
          d="M15.1984 9.25003H7.98189C7.77571 9.25003 7.62109 9.10002 7.62109 8.90002V8.25003C7.62109 8.05003 7.77571 7.90002 7.98189 7.90002H15.1984C15.4046 7.90002 15.5592 8.05003 15.5592 8.25003V8.85004C15.5592 9.10004 15.4046 9.25003 15.1984 9.25003Z"
          fill="white"
        />
        <path
          d="M5.92451 12.2H4.79052C4.58433 12.2 4.42969 12.05 4.42969 11.85V11.25C4.42969 11.05 4.58433 10.9 4.79052 10.9H5.92451C6.1307 10.9 6.28534 11.05 6.28534 11.25V11.85C6.28534 12.05 6.1307 12.2 5.92451 12.2Z"
          fill="white"
        />
        <path
          d="M15.1984 12.2H7.98189C7.77571 12.2 7.62109 12.05 7.62109 11.85V11.25C7.62109 11.05 7.77571 10.9 7.98189 10.9H15.1984C15.4046 10.9 15.5592 11.05 15.5592 11.25V11.85C15.5592 12.05 15.4046 12.2 15.1984 12.2Z"
          fill="white"
        />
        <path
          d="M5.92451 15.2H4.79052C4.58433 15.2 4.42969 15.05 4.42969 14.85V14.25C4.42969 14.05 4.58433 13.9 4.79052 13.9H5.92451C6.1307 13.9 6.28534 14.05 6.28534 14.25V14.85C6.28534 15.05 6.1307 15.2 5.92451 15.2Z"
          fill="white"
        />
        <path
          d="M15.1984 15.2H7.98189C7.77571 15.2 7.62109 15.05 7.62109 14.85V14.25C7.62109 14.05 7.77571 13.9 7.98189 13.9H15.1984C15.4046 13.9 15.5592 14.05 15.5592 14.25V14.85C15.5592 15.05 15.4046 15.2 15.1984 15.2Z"
          fill="white"
        />
        <path
          d="M5.92451 18.2H4.79052C4.58433 18.2 4.42969 18.05 4.42969 17.85V17.25C4.42969 17.05 4.58433 16.9 4.79052 16.9H5.92451C6.1307 16.9 6.28534 17.05 6.28534 17.25V17.85C6.28534 18.05 6.1307 18.2 5.92451 18.2Z"
          fill="white"
        />
        <path
          d="M15.1984 18.2H7.98189C7.77571 18.2 7.62109 18.05 7.62109 17.85V17.25C7.62109 17.05 7.77571 16.9 7.98189 16.9H15.1984C15.4046 16.9 15.5592 17.05 15.5592 17.25V17.85C15.5592 18.05 15.4046 18.2 15.1984 18.2Z"
          fill="white"
        />
        <path
          d="M17.9326 12.3C17.9326 12.25 17.9326 12.15 17.8811 12.1C17.7264 11.55 17.2625 11.1 16.6955 10.95C16.1285 10.8 15.5099 10.95 15.0976 11.35L8.60271 17.65C8.19034 18.05 7.82953 18.55 7.52025 19.05V19.1C7.41716 19.3 7.26251 19.55 7.15942 19.8L6.48932 21.5C6.38623 21.75 6.54086 22 6.79859 22.05C6.85014 22.05 6.90169 22.05 6.90169 22.05C6.95324 22.05 7.00479 22.05 7.00479 22L8.13881 21.6L8.75734 21.35C9.01508 21.25 9.22127 21.15 9.47901 21H9.53054C10.046 20.7 10.5099 20.4 10.9739 19.95L15.8192 15.25L17.4687 13.65C17.7264 13.4 17.8811 13.05 17.9326 12.7C17.9326 12.65 17.9326 12.55 17.9326 12.5C17.9326 12.45 17.9326 12.4 17.9326 12.3Z"
          fill="#7447CC"
        />
      </svg>
    );
  } else if (type === "PDF") {
    return (
      <svg
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5595 0C19.1059 0 20.3945 1.2 20.3945 2.75V21.25C20.3945 22.75 19.1574 24 17.5595 24H3.22958C1.68319 24 0.394531 22.8 0.394531 21.25V5.89999C0.394531 5.54999 0.549161 5.24999 0.755347 5.04999L5.60072 0.350006C5.85845 0.100006 6.16772 0 6.477 0H17.5595V0Z"
          fill="#FACAB3"
        />
        <path
          d="M5.65226 0.350037C5.75535 0.250037 5.90999 0.150037 6.01309 0.100037V2.85004C6.01309 4.85004 4.3636 6.45004 2.30174 6.45004H0.394531V5.95004C0.394531 5.60004 0.549161 5.30004 0.755347 5.10004L5.65226 0.350037Z"
          fill="#F7A983"
        />
        <path
          d="M0.546875 5.45004C0.598421 5.30004 0.701514 5.20004 0.804607 5.10004L5.64997 0.400024C5.75306 0.300024 5.9077 0.200024 6.0108 0.150024V2.75003C6.0108 4.25003 4.77368 5.50003 3.17574 5.50003H0.546875V5.45004Z"
          fill="#7447CC"
        />
        <path
          d="M8.69137 12.45C8.69137 12.85 8.58827 13.15 8.33054 13.35C8.07281 13.55 7.76353 13.7 7.29961 13.7H7.04188V14.9H6.0625V11.35H7.35116C7.81508 11.35 8.1759 11.45 8.38209 11.65C8.58827 11.85 8.69137 12.1 8.69137 12.45ZM6.99033 12.95H7.19651C7.35115 12.95 7.45425 12.9 7.55734 12.85C7.66044 12.75 7.71198 12.65 7.71198 12.5C7.71198 12.25 7.55734 12.15 7.29961 12.15H7.04188V12.95H6.99033Z"
          fill="#7447CC"
        />
        <path
          d="M12.2459 13.05C12.2459 13.65 12.0913 14.1 11.7304 14.45C11.3696 14.75 10.9057 14.95 10.2871 14.95H9.10156V11.4H10.3902C10.9572 11.4 11.4212 11.55 11.782 11.85C12.0913 12.05 12.2459 12.5 12.2459 13.05ZM11.215 13.1C11.215 12.75 11.1634 12.55 11.0088 12.35C10.8541 12.2 10.648 12.1 10.3902 12.1H10.0809V14.1H10.2871C10.5964 14.1 10.8026 14 10.9572 13.85C11.1634 13.7 11.215 13.45 11.215 13.1Z"
          fill="#7447CC"
        />
        <path
          d="M13.7958 14.9H12.8164V11.35H14.9813V12.1H13.7958V12.8H14.8783V13.55H13.7958V14.9Z"
          fill="#7447CC"
        />
      </svg>
    );
  } else if (type === "IFrame") {
    return (
      <svg
        width="25"
        height="20"
        viewBox="0 0 25 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.743 20H2.84296C1.69296 20 0.792969 19.1262 0.792969 18.0097V2.03883C0.792969 0.922327 1.69296 0.0485229 2.84296 0.0485229H22.743C23.893 0.0485229 24.793 0.922327 24.793 2.03883V18.0097C24.793 19.0776 23.893 20 22.743 20Z"
          fill="#FACAB3"
        />
        <path
          d="M22.743 0H2.84296C1.69296 0 0.792969 0.873774 0.792969 1.99028V2.91262H24.793V1.99028C24.793 0.922318 23.893 0 22.743 0Z"
          fill="#7447CC"
        />
        <path
          d="M3.74451 1.16498H3.14453V1.74751H3.74451V1.16498Z"
          fill="#551FC1"
        />
        <path
          d="M4.99451 1.16498H4.39453V1.74751H4.99451V1.16498Z"
          fill="#551FC1"
        />
        <path
          d="M6.24451 1.16498H5.64453V1.74751H6.24451V1.16498Z"
          fill="#551FC1"
        />
        <path
          d="M24.793 2.9126H0.792969V3.20385H24.793V2.9126Z"
          fill="#551FC1"
        />
        <path
          d="M24.793 4.6116H2.19296C1.44296 4.6116 0.792969 4.02908 0.792969 3.25238H24.793V4.6116Z"
          fill="#F7A983"
        />
        <path
          d="M9.50232 15.2913C9.20232 15.2913 8.90232 15.1457 8.75232 14.903L5.90234 11.3107L8.75232 7.7185C9.10232 7.33015 9.70233 7.23308 10.1023 7.52434C10.5023 7.86414 10.6023 8.44668 10.3023 8.83502L8.35233 11.3107L10.3023 13.7865C10.6523 14.1748 10.5523 14.8059 10.1023 15.0971C9.90233 15.1942 9.70232 15.2913 9.50232 15.2913Z"
          fill="white"
        />
        <path
          d="M16.1003 15.2913C15.9003 15.2913 15.7003 15.2427 15.5003 15.0971C15.1003 14.7573 15.0003 14.1748 15.3003 13.7864L17.2503 11.3107L15.3003 8.83497C14.9503 8.44662 15.0503 7.81555 15.5003 7.52429C15.9003 7.18448 16.5503 7.28155 16.8503 7.71845L19.7003 11.3107L16.8503 14.9029C16.7003 15.1457 16.4003 15.2913 16.1003 15.2913Z"
          fill="white"
        />
        <path
          d="M12.1444 14.9515C12.0944 14.9515 12.0944 14.9515 12.0444 14.9515C11.7944 14.903 11.5944 14.6603 11.6444 14.4175L12.8944 8.10686C12.9444 7.86414 13.1944 7.66994 13.4444 7.71849C13.6944 7.76703 13.8944 8.00977 13.8444 8.25248L12.5944 14.5632C12.5944 14.7573 12.3944 14.9515 12.1444 14.9515Z"
          fill="#7447CC"
        />
      </svg>
    );
  } else if (type === "Webinar") {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8884 23.9954H18.9882L16.5886 12.4977H15.4389L16.6386 18.2465H8.99015L10.1899 12.4977H9.09014L6.64062 23.9954H7.7404L8.84019 18.8964H16.7886L17.8884 23.9954Z"
          fill="#551FC1"
        />
        <path
          d="M14.3385 0.899831H11.2891V0.799844C11.2891 0.349934 11.639 0 12.0889 0H13.5386C13.9885 0 14.3385 0.349934 14.3385 0.799844V0.899831Z"
          fill="#551FC1"
        />
        <path
          d="M23.2887 14.5971H2.39281C1.9429 14.5971 1.54297 14.1972 1.54297 13.7473V2.69946H24.1385V13.7473C24.1385 14.2472 23.7386 14.5971 23.2887 14.5971Z"
          fill="#FACAB3"
        />
        <path
          d="M24.0384 2.69956H1.54282C1.1429 2.69956 0.792969 2.34963 0.792969 1.94971V1.39981C0.792969 0.999894 1.1429 0.649963 1.54282 0.649963H24.0384C24.4383 0.649963 24.7882 0.999894 24.7882 1.39981V1.94971C24.8382 2.34963 24.4883 2.69956 24.0384 2.69956Z"
          fill="#7447CC"
        />
        <path
          d="M24.1385 2.69946H1.54297V3.34933H24.1385V2.69946Z"
          fill="#F7A983"
        />
        <path
          d="M8.29237 9.29821V8.64834C8.29237 8.09845 7.99243 7.64854 7.54252 7.3486C7.09261 7.09865 6.54272 7.09865 6.04282 7.3486C5.59291 7.59855 5.29297 8.09845 5.29297 8.64834V9.29821C5.29297 9.74812 5.54291 10.198 5.94283 10.398C6.44273 10.6979 7.09261 10.6979 7.59252 10.398C8.04243 10.198 8.29237 9.79811 8.29237 9.29821Z"
          fill="#7447CC"
        />
        <path
          d="M10.0425 15.347H3.59375V14.2972C3.59375 12.4976 5.04346 11.0479 6.84311 11.0479C8.64275 11.0479 10.0925 12.4976 10.0925 14.2972V15.347H10.0425Z"
          fill="#7447CC"
        />
        <path
          d="M12.2415 16.9967H1.39364C1.0937 16.9967 0.84375 16.7468 0.84375 16.4468V15.9469C0.84375 15.647 1.0937 15.397 1.39364 15.397H12.2415C12.5414 15.397 12.7914 15.647 12.7914 15.9469V16.4468C12.8414 16.7468 12.5414 16.9967 12.2415 16.9967Z"
          fill="#551FC1"
        />
        <path
          d="M12.1401 16.9967H1.49219V23.9953H12.1401V16.9967Z"
          fill="#551FC1"
        />
      </svg>
    );
  }
};
