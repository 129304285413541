type Props = {
  size?: number;
  fill?: string;
  className?: string;
};

export const IconArrowDown: React.FC<Props> = ({
  size = 20,
  fill = "#3E3851",
  className = "",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.31689 10.3667L13.0502 6.5667C13.3169 6.30003 13.3169 5.90003 13.0502 5.63336C12.7836 5.3667 12.3836 5.3667 12.1169 5.63336L8.78356 8.90003L5.45023 5.63336C5.18356 5.3667 4.78356 5.3667 4.51689 5.63336C4.38356 5.7667 4.31689 5.90003 4.31689 6.10003C4.31689 6.30003 4.38356 6.43336 4.51689 6.5667L8.25023 10.3667C8.58356 10.6334 8.98356 10.6334 9.31689 10.3667C9.25023 10.3667 9.25023 10.3667 9.31689 10.3667Z"
        fill={fill}
      />
    </svg>
  );
};
